import {} from "../util/productUtil"
import { isRunTime } from "../util/generalUtil"
import BasketType from "../model/BasketType"
import {
  ProductTaxonomyType,
  ProductType,
  VariantType,
} from "@social-supermarket/social-supermarket-model"
import { getPriceRange, parsePrice } from "@social-supermarket/social-supermarket-components"

declare global {
  interface Window {
    dataLayer: any
  }
}

export const productImpressions = (
  startingIndex: number,
  products: ProductType[],
  collectionName: string
) => {
  trackEcommerce("view_item_list", {
    currency: "GBP",
    item_list_id: collectionName,
    item_list_name: collectionName,
    items: mapProductsToItems(products, collectionName, startingIndex),
  })
}

export const productClick = (product: ProductType, position: number, collectionName: string) => {
  trackEcommerce("select_item", {
    currency: "GBP",
    item_list_id: collectionName,
    item_list_name: collectionName,
    items: [mapProductToItem(product, collectionName, position)],
  })
}

export const productView = (product: ProductType) => {
  const range = getPriceRange(product)
  trackEcommerce("view_item", {
    currency: "GBP",
    value: range[0],
    items: [mapProductToItem(product)],
  })
}

export const addToBasket = (product: ProductType, variant: VariantType, quantity: number) => {
  const range = getPriceRange(product, variant ? [variant] : null, quantity)
  trackEcommerce("add_to_cart", {
    currency: "GBP",
    value: range[1] * quantity,
    items: [mapVariantToItem(product, variant, undefined, undefined, quantity)],
  })
}

export const removeFromBasket = (product: ProductType, variant: VariantType) => {
  trackEcommerce("remove_from_cart", {
    currency: "GBP",
    value: getPriceRange(product, variant ? [variant] : null)[0].toFixed(2),
    items: [mapVariantToItem(product, variant, undefined, undefined)],
  })
}

export const startCheckout = (basket: BasketType) => {
  trackEcommerce("begin_checkout", {
    currency: "GBP",
    value: basket.total,
    items: basket.items.map(item =>
      mapVariantToItem(item.product, item.variant, undefined, undefined, item.quantity)
    ),
  })
}

export const orderSuccess = (orderId: number, basket: BasketType) => {
  trackEcommerce("purchase", {
    currency: "GBP",
    value: basket.total,
    transaction_id: orderId,
    tax: basket.totalTax,
    shipping: basket.shippingMethod?.total,
    items: basket.items.map(item =>
      mapVariantToItem(item.product, item.variant, undefined, undefined, item.quantity)
    ),
  })
}

export const corporateEnquiry = (value: number) => {
  track("corporate-contact", {
    estimated_value: value,
  })
}

export const modalOpened = () => {
  track("modal_opened", {})
}

export const conversion = (eventName, value: number) => {
  track(eventName, {
    estimated_value: value,
  })
}

export const platformBookADemo = () => {
  trackEcommerce("platform-book-demo", undefined)
}

export const platformGetStarted = () => {
  trackEcommerce("platform-get-started", undefined)
}

const mapProductsToItems = (
  products: ProductType[],
  collectionName: string,
  startingIndex: number
) => {
  return products.map((product, i) => mapProductToItem(product, collectionName, startingIndex + i))
}

const mapProductToItem = (
  product: ProductType,
  collectionName?: string,
  index?: number,
  quantity?: number
) => {
  const item = {
    item_id: product.productId,
    item_name: product.name,
    item_brand: product.brand?.name,
    item_list_id: collectionName,
    item_list_name: collectionName,
    price: product.price ? parsePrice(product.price) : undefined,
    index: index,
    quantity: quantity,
  }

  product.categories?.forEach((category, i) => {
    const pos = i + 1
    if (pos === 1) {
      item["item_category"] = category.name
    } else {
      item[`item_category${i}`] = category.name
    }
  })

  return item
}

const mapVariantToItem = (
  product: ProductType,
  variant: VariantType,
  collectionName?: string,
  index?: number,
  quantity?: number
) => {
  const priceRange = getPriceRange(product, variant ? [variant] : null)
  const item = {
    item_id: product.productId,
    item_name: variant?.name || product.name,
    item_brand: product.brand?.name,
    item_list_id: collectionName,
    item_list_name: collectionName,
    item_variant: variant?.variantId,
    price: priceRange[1],
    index: index,
    quantity: quantity,
  }

  product?.categories?.forEach((category, i) => {
    const pos = i + 1
    if (pos === 1) {
      item["item_category"] = category.name
    } else {
      item[`item_category${i}`] = category.name
    }
  })

  return item
}

const trackEcommerce = (eventName: string, object: object) => {
  if (canTrack()) {
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: eventName,
      ecommerce: object,
    })
  }
}

const track = (eventName: string, object: object) => {
  if (canTrack()) {
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: eventName,
      ...object,
    })
  }
}

const canTrack = (): boolean => {
  return isRunTime() && window.dataLayer
}

const getCategory = (product: ProductType): ProductTaxonomyType => {
  return product?.categories && product.categories.length > 0 ? product.categories[0] : undefined
}
